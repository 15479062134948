import React from "react";

export default function CopyrightFooter() {
  return (
    <div id="copyright_footer">
      <div className="inner">
        <p className="copyright">
          Contents ©2015-2020 Sony Global Education, Inc.
        </p>
      </div>
    </div>
  );
}
