import React from "react";
import { useTranslation, Trans, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import CopyrightFooter from "../../components/copyright_footer";
import HeaderLogoImage from "../../images/common/header_logo@2x.png";
import RoomImage from "../../images/common/img_room.png";
import HakaseImage from "../../images/common/img_shibuya_hakase.png";
import ShibuyaImage from "../../images/common/img_shibuya.png";
import CalendarImage from "../../images/common/img_calendar.png";
import TennisTourneyImage from "../../images/common/img_tennis.png";
import NinjaImage from "../../images/common/img_ninja.png";
import LoadHakaseImage from "../../images/common/loding_hakase.gif";

import "../../styles/quiz.scss";

export default function Quiz() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Layout>
      <div className="quiz" id="quizPage">
        <div id="fixed_header">
          <div className="header_img"></div>
          <div className="header_box">
            <div id="navigation">
              <ul id="progressbar">
                <li className="active">1</li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div className="header">
              <div className="logo">
                <Link className="moveToTopLogo" to="/mmind">
                  <img src={HeaderLogoImage} alt="Header logo@2x" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="content_box">
          <div id="fullpage">
            <div className="section" id="q1">
              <div id="text_column">
                <div className="inner">
                  <div className="title">{t("mmind.q1.title")}</div>
                  <div
                    className={`description dynamic_line_height ${
                      currentLanguage === "en" ? "line_height_narrow" : ""
                    }`}
                  >
                    <Trans i18nKey="mmind.q1.description" />
                  </div>
                </div>
              </div>
              <div id="image_column">
                <div className="inner">
                  <img
                    alt="Question-1"
                    className="img-responsive center-block"
                    src={RoomImage}
                  />
                </div>
              </div>
              <div id="answer_column">
                <div className="inner">
                  <button
                    data-aid="0-0"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q1.answer_a")}
                  </button>
                  <button
                    data-aid="0-1"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q1.answer_b")}
                  </button>
                  <button
                    data-aid="0-2"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q1.answer_c")}
                  </button>
                  <button
                    data-aid="0-3"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q1.answer_d")}
                  </button>
                </div>
              </div>
              <CopyrightFooter />
            </div>

            <div className="section" id="q1">
              <div id="text_column">
                <div className="inner">
                  <div className="title">{t("mmind.q2.title")}</div>
                  <div
                    className={`description dynamic_line_height ${
                      currentLanguage === "en" ? "line_height_narrow" : ""
                    }`}
                  >
                    <p id="q2_text">
                      <Trans i18nKey="mmind.q2.description" />
                    </p>
                    <p id="q2_image_area">
                      <img
                        alt=""
                        className="img-responsive center-block icon_img"
                        src={HakaseImage}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div id="image_column">
                <div className="inner">
                  <img
                    alt="Question-2"
                    className="img-responsive center-block"
                    src={ShibuyaImage}
                  />
                </div>
              </div>
              <div id="answer_column">
                <div className="inner">
                  <button
                    data-aid="1-0"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q2.answer_a")}
                  </button>
                  <button
                    data-aid="1-1"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q2.answer_b")}
                  </button>
                  <button
                    data-aid="1-2"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q2.answer_c")}
                  </button>
                  <button
                    data-aid="1-3"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q2.answer_d")}
                  </button>
                </div>
              </div>
              <CopyrightFooter />
            </div>

            <div className="section" id="q3">
              <div id="text_column">
                <div className="inner">
                  <div className="title">{t("mmind.q3.title")}</div>
                  <div
                    className={`description dynamic_line_height ${
                      currentLanguage === "en" ? "line_height_narrow" : ""
                    }`}
                  >
                    <Trans i18nKey="mmind.q3.description" />
                  </div>
                </div>
              </div>
              <div id="image_column">
                <div className="inner">
                  <img
                    alt="Question-3"
                    className="img-responsive center-block"
                    src={CalendarImage}
                  />
                </div>
              </div>
              <div id="answer_column">
                <div className="inner">
                  <button
                    data-aid="2-0"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q3.answer_a")}
                  </button>
                  <button
                    data-aid="2-1"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q3.answer_b")}
                  </button>
                  <button
                    data-aid="2-2"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q3.answer_c")}
                  </button>
                  <button
                    data-aid="2-3"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q3.answer_d")}
                  </button>
                </div>
              </div>
              <CopyrightFooter />
            </div>

            <div className="section" id="q4">
              <div id="text_column">
                <div className="inner">
                  <div className="title">{t("mmind.q4.title")}</div>
                  <div
                    className={`description dynamic_line_height ${
                      currentLanguage === "en" ? "line_height_narrow" : ""
                    }`}
                  >
                    <Trans i18nKey="mmind.q4.description" />
                  </div>
                </div>
              </div>
              <div id="image_column">
                <div className="inner">
                  <img
                    alt="Question-4"
                    className="img-responsive center-block"
                    src={TennisTourneyImage}
                  />
                </div>
              </div>
              <div id="answer_column">
                <div className="inner">
                  <button
                    data-aid="3-0"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q4.answer_a")}
                  </button>
                  <button
                    data-aid="3-1"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q4.answer_b")}
                  </button>
                  <button
                    data-aid="3-2"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q4.answer_c")}
                  </button>
                  <button
                    data-aid="3-3"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q4.answer_d")}
                  </button>
                </div>
              </div>
              <CopyrightFooter />
            </div>

            <div className="section" id="q5">
              <div id="text_column">
                <div className="inner">
                  <div className="title">{t(`mmind.q5.title`)}</div>
                  <div
                    className={`description dynamic_line_height ${
                      currentLanguage === "en" ? "line_height_narrow" : ""
                    }`}
                  >
                    <Trans i18nKey="mmind.q5.description" />
                  </div>
                </div>
              </div>
              <div id="image_column">
                <div className="inner">
                  <img
                    alt="Question-5"
                    className="img-responsive center-block"
                    src={NinjaImage}
                  />
                </div>
              </div>
              <div id="answer_column">
                <div className="inner">
                  <button
                    data-aid="4-0"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q5.answer_a")}
                  </button>
                  <button
                    data-aid="4-1"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q5.answer_b")}
                  </button>
                  <button
                    data-aid="4-2"
                    className="button btn btn-default btn-lg answer left"
                  >
                    {t("mmind.q5.answer_c")}
                  </button>
                  <button
                    data-aid="4-3"
                    className="button btn btn-default btn-lg answer right"
                  >
                    {t("mmind.q5.answer_d")}
                  </button>
                </div>
              </div>
              <CopyrightFooter />
            </div>

            <div className="section" id="calc">
              <div id="calc_column">
                <div className="wrapper">
                  <div className="inner">
                    <div className="indicator">
                      <div className="title shimmer">
                        {t("mmind.calc.calculating")}
                      </div>
                      <img
                        alt=""
                        className="img-responsive center-block"
                        src={LoadHakaseImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
